<template>
  <div
    class="bg-orange-100 text-orange-700 text-center p-2 border-b border-orange-300 text-xs"
  >
    Are you a developer wondering where the FormKit Framework docs went? They're
    now at
    <a href="https://developers.formkit.com" class="underline"
      >developers.formkit.com</a
    >.
  </div>
</template>

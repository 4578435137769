<script setup lang="ts">
const system = useSystemStore();
const { formModalOpen } = storeToRefs(system);
const { user, clear } = useUserSession();
</script>

<template>
  <SiteHeaderContainer class="h-14">
    <div class="flex items-center gap-2 shrink-0">
      <NuxtLink class="flex items-center cursor-pointer" to="/">
        <Logo :mark-only="false" />
      </NuxtLink>
    </div>
    <div class="ml-auto flex items-center gap-4 sm:gap-8 shrink">
      <div class="flex items-center gap-6">
        <NuxtLink
          v-if="!user"
          class="block font-semibold text-sm sm:text-base text-gray-500 hover:text-pink-600"
          to="/pricing"
        >
          Pricing
        </NuxtLink>
        <NuxtLink
          v-if="!user"
          class="font-semibold text-sm sm:text-base text-gray-500 hover:text-pink-600 hidden sm:block"
          to="/login"
        >
          Log in
        </NuxtLink>
        <UserNavigation v-else>
          <Avatar />
        </UserNavigation>
        <Button
          class="!bg-pink-600 hover:!bg-black !text-slate-100"
          @click="formModalOpen = true"
        >
          <Icon class="mr-2 h-3.5 w-3.5 sm:h-4 sm:w-4" name="lucide:edit" />
          <span class="text-xs sm:text-sm">{{
            !user ? "Start Now" : "New Form"
          }}</span>
        </Button>
      </div>
    </div>
  </SiteHeaderContainer>
</template>

<script setup lang="ts">
import { ConfigProvider } from "radix-vue";

const useIdFunction = () => useId();
</script>

<template>
  <ConfigProvider :use-id="useIdFunction">
    <div
      :class="`
    w-full
    flex
    flex-col
    min-h-[100dvh]
  `"
    >
      <FormKitDevsNotice />
      <MarketingSiteHeader />
      <main class="flex justify-center flex-1 h-full overflow-clip -mt-14">
        <slot />
      </main>
      <MarketingSiteFooter />
      <NewFormModal />
      <Toast />
    </div>
  </ConfigProvider>
</template>
